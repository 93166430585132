// Import all BOOTSTRAP plugins
import * as bootstrap from 'bootstrap';

// Custome stylesheet, include the Bootstrap Styles
import '../scss/style.scss'

// Webcomponents
// import '../components/lbht-page-components';
    
let callback = function(){

    // Spinner
    let docSpinner = document.getElementById('spinner');
    let spinner = function () {
        setTimeout(function () {
            if (docSpinner.children.length > 0) {
                docSpinner.classList.remove('show');
            }
        }, 1);
    };
    spinner();


    // Sticky Navbar
    window.addEventListener('scroll', (e) => {
        let stickyTop = document.querySelector('.sticky-top');
        if (window.scrollY > 300) {
            stickyTop.classList.add('shadow-sm');
            stickyTop.style.top = '0px';
        } else {
            stickyTop.classList.remove('shadow-sm');
            stickyTop.style.top ='-100px';
        }
      });
    
    
    // Back to top button
    let backToTop = document.querySelector('.back-to-top');
    // Fade the Back to Top button in/out
    window.addEventListener('scroll', (e) => {
        if (window.scrollY > 300) {
            fadeIn(backToTop, 300);
        } else {
            fadeOut(backToTop, 300);
        }
    });

    // add on click event to scroll to top of window
    backToTop.addEventListener('click', (e) => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    });

};
// End of DOM Callback Function(s)


if (
    document.readyState === "complete" ||
    (document.readyState !== "loading" && !document.documentElement.doScroll)
) {
  callback();
} else {
  document.addEventListener("DOMContentLoaded", callback);
}

// FadeIn Function
function fadeIn( elem, ms )
{
  if( ! elem )
    return;

  elem.style.opacity = 0;
  elem.style.filter = "alpha(opacity=0)";
  elem.style.display = "inline-block";
  elem.style.visibility = "visible";

  if ( ms ) {
    var opacity = 0;
    var timer = setInterval( function() {
        opacity += 50 / ms;
        if( opacity >= 1 ) {
            clearInterval(timer);
            opacity = 1;
        }
        elem.style.opacity = opacity;
        elem.style.filter = "alpha(opacity=" + opacity * 100 + ")";
    }, 50 );
  }
  else {
    elem.style.opacity = 1;
    elem.style.filter = "alpha(opacity=1)";
}
}

function fadeOut( elem, ms ) {
  if ( ! elem )
    return;

  if ( ms ) {
    var opacity = 1;
    var timer = setInterval( function() {
        opacity -= 50 / ms;
        if( opacity <= 0 ) {
            clearInterval(timer);
            opacity = 0;
            elem.style.display = "none";
            elem.style.visibility = "hidden";
        }
        elem.style.opacity = opacity;
        elem.style.filter = "alpha(opacity=" + opacity * 100 + ")";}, 50 );
    } else {
        elem.style.opacity = 0;
        elem.style.filter = "alpha(opacity=0)";
        elem.style.display = "none";
        elem.style.visibility = "hidden";
    }
}
